import { template as template_795f184a19464671bdd781cb4e85577a } from "@ember/template-compiler";
const SidebarSectionMessage = template_795f184a19464671bdd781cb4e85577a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
