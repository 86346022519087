import { template as template_8f2b3fe4c5b4408d90946846e8749801 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8f2b3fe4c5b4408d90946846e8749801(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
