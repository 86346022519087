import { template as template_0458528e66a6407590fdddfe8251713b } from "@ember/template-compiler";
const FKLabel = template_0458528e66a6407590fdddfe8251713b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
